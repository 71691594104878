<template>
  <b-tooltip
    type="is-light"
    :triggers="['click']"
    :auto-close="['outside', 'escape', 'inside']"
  >
    <template v-slot:content>
      <p>Вы действительно хотите удалить?</p>
      <b-button type="is-danger" size="is-small" outlined @click="$emit('confirm')">Да</b-button>
    </template>
    <b-button type="is-danger" icon-right="trash"/>
  </b-tooltip>
</template>

<script>
export default {
  name: "ConfirmAction",
}
</script>

