<template>
  <div>
    <b-modal
      :active="active"
      width="95vw"
      scroll="keep"
      @close="$emit('close')"
    >
      <b-loading v-model="isLoading" :can-cancel="true"></b-loading>
      <div v-if="selectedItem" class="card px-5 pt-6 pb-4">
        <b-field
          label="Название"
          :message="errors && errors['name']"
          :type="errors && errors['name'] && 'is-danger'"
        >
          <b-input
            v-model="name"
          />
        </b-field>
        <b-field
          label="Добавление полей"
          :message="(errors && errors['fields']) || 'Нажмите Enter чтобы добавить'"
          :type="errors && errors['fields'] && 'is-danger'"
        >
          <b-taginput
            v-model="fields"
            ellipsis
            icon="greater-than"
            placeholder="Добавить поле"
            aria-close-label="Удалить поле"
            class="my-taginput"
          />
        </b-field>
        <div class="has-text-right pt-2">
          <b-button type="is-info" @click="createCatalog">Сохранить</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CatalogModal",
  props: ["active", "selectedItem"],
  data() {
    return {
      isLoading: false,
      createLoading: false,
      name: "",
      fields: [],
      errors: [],
    }
  },
  watch: {
    active(newValue) {
      if (newValue && this.selectedItem["id"]) {
        this.fetchCatalog()
      } else if ( !newValue) {
        this.fields = []
        this.errors = []
        this.name = ""
      }
    },
  },
  methods: {
    async fetchCatalog() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/catalogs/${this.selectedItem["id"]}`)
        this.name = data?.data?.["name"] || ""
        this.fields = data?.data?.["fields"] || []
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false
    },
    async createCatalog() {
      this.errors = []

      const METHOD = this.selectedItem?.["id"] ? "put" : "post"
      const URI = `admin/catalogs/${this.selectedItem?.["id"] || ""}`

      try {
        const { data } = await this.$axios[METHOD](URI, {
          name: this.name,
          fields: this.fields,
        })
        if (data?.code === 201) {
          this.$emit("saved")
          this.$emit("close")
        }
      } catch ({ error }) {
        this.errors = error.response?.data?.errors || []
        throw new Error(error)
      }
    },
  },
}
</script>

