<template>
  <div class="has-background-white">
    <b-table
      :data="data"
      :loading="loading"
      hoverable
      striped
      class="base-table"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="name" label="Название" v-slot="props">
        <router-link :to="`catalogs/${props.row.id}`">{{ props.row.name }}</router-link>
      </b-table-column>

      <b-table-column field="created_at" label="Дата создания" v-slot="props">
        {{ props.row.created_at }}
      </b-table-column>

      <b-table-column label="Опции" v-slot="props">
        <div>
          <b-button
            class="mr-2"
            type="is-warning is-light"
            icon-right="edit"
            @click="$emit('edit', props.row)"
          />
          <ConfirmAction
            @confirm="$emit('delete', props.row.id)"
          />
<!--          <b-button type="is-danger is-light" icon-right="trash" @click.stop=""/>-->
        </div>
      </b-table-column>
    </b-table>
    <section class="mt-5 p-3">
      <b-pagination
        :total="total"
        :per-page="perPage"
        order="is-centered"
        icon-pack="fas"
        :current="currentPage"
        class="base-table__pagination"
        @change="changePage"
      >
        <template #previous="props">
          <b-pagination-button
            :page="props.page"
            @click="changePage(currentPage-1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-left']" />
          </b-pagination-button>
        </template>
        <template #next="props">
          <b-pagination-button
            :page="props.page"
            @click="changePage(currentPage+1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-right']" />
          </b-pagination-button>
        </template>
      </b-pagination>
    </section>
  </div>
</template>

<script>
import ConfirmAction from "@/components/Base/ConfirmAction.vue"
export default {
  name: "CatalogTable",
  components: { ConfirmAction },
  props: {
    data: Array,
    loading: Boolean,
    total: Number,
    perPage: Number,
    currentPage: Number,
    withOperations: Boolean
  },
  methods: {
    changePage(page) {
      this.$emit('change', page)
    }
  }
};
</script>

<style lang="scss">

</style>
