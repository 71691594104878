<template>
  <div class="column pt-0">
    <CatalogModal
      :active="isModalActive"
      :selected-item="selectedCatalog"
      @close="closeDialog"
      @saved="fetchCatalogs"
    />

    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
      <h2 class="is-size-5 has-text-weight-semibold mb-4">Каталог</h2>
      <b-button type="is-info" @click="openDialog">Добавить</b-button>
    </div>
    <CatalogTable
      :loading="isLoading"
      :current-page="currentPage"
      :per-page="perPage"
      :data="catalogs"
      :total="catalogsCount"
      @change="fetchCatalogs"
      @edit="openDialog"
      @delete="deleteCatalog"
      @rowClick="toCatalogPage"
    />
  </div>
</template>

<script>
import CatalogModal from "@/components/Catalog/CatalogModal.vue"
import CatalogTable from "@/components/Catalog/CatalogTable.vue"

export default {
  name: "CatalogIndex",
  components: { CatalogTable, CatalogModal },
  data() {
    return {
      isLoading: true,
      isModalActive: false,
      currentPage: 1,
      perPage: 5,
      selectedCatalog: undefined,
      catalogs: [],
      catalogsCount: 0,
    }
  },
  mounted() {
    this.fetchCatalogs()
  },
  methods: {
    async fetchCatalogs(page) {
      this.isLoading = true
      this.currentPage = page || 1
      const offset = (this.currentPage * this.perPage) - this.perPage

      try {
        const { data } = await this.$axios.get(`admin/catalogs/paginate/${offset}/${this.perPage}`)
        this.catalogs = data.data || []
        this.catalogsCount = data.count || 0
      } catch (e) {
        throw new Error(e)
      }

      this.isLoading = false
    },
    async deleteCatalog(id) {
      try {
        const { data } = await this.$axios.delete(`admin/catalogs/${id}`)
        if(data?.code === 202) {
          await this.fetchCatalogs()
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    openDialog(item) {
      this.selectedCatalog = item
      this.isModalActive = true
    },
    closeDialog() {
      this.isModalActive = false
      this.selectedCatalog = undefined
    },
    toCatalogPage(item) {
      this.$router.push({ name: "CatalogShow", params: { catalog_id: item.id } })
    }
  },
}
</script>

<style scoped>

</style>
